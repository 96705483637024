import React, { useState } from 'react';

import {Image} from "react-bootstrap";
const ImageHoverFadeInComponent = ({ src, hoverSrc }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            className="custom-image-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Image src={src} alt="" className={!isHovered ? 'visible' : ''}/>
            <Image src={hoverSrc} alt="" className={isHovered ? 'visible hover' : 'hover'}/>

        </div>
    );
};

export default ImageHoverFadeInComponent;