import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import '../assets/scss/Services.scss';

const enContent = () => {
    return <>
        <div className={'text-center'}><h2>Terms of Use</h2></div>
        <br/>
        <br/>
        <p>
            <h4>Use of information</h4>
            <h6>Whenever anyone accesses this website, our servers are capable of collecting specific data that allows
                us to monitor the number of website visits. Such information is used to continue the improvement and
                development process. The data forwarded to our servers do not include any personal data that would allow
                tracing and tracking individual visitors. Personal data means information that references a specific
                person or entity - name or e-mail address.</h6>
        </p>
        <p>
            <h4>Third party information</h4>
            <h6>Our website provides links to other websites that are not property of Takida Terminal, are not a part
                of, or have no clear links with, Takida Terminal (except for their company websites that are distinctly
                marked as being part of the Takida Terminal websites). Therefore, we give no guarantees as to the
                contents or the confidentiality practices of such websites. For clarification purposes, “third party”
                means any person, entity or group of persons and/or entities that is not, nor has it ever been declared
                a Takida Terminal member, employee, affiliate or subsidiary.</h6>
        </p>
        <p>
            <h4>Information accuracy</h4>
            <h6>Takida Terminal is made of distinct entities, however, on our website we use the term “We” in reference
                to the Takida Terminal companies in general or where there is no need to single out a member of our
                group. Takida Terminal has used his best efforts to provide information as exact as possible on this
                website. However, we do not guarantee the accuracy, integrity and timeliness of all the information
                herein provided by us directly or by third parties. Therefore, any visitor who uses the information
                provided on this website is doing so at his own risk.</h6>

        </p>
    </>
}
const roContent = () => {
    return <>
        <div className={'text-center'}><h2>Termeni și Conditii</h2></div>
        <br/>
        <br/>
        <p>
            <h4>Folosirea informatiilor</h4>
            <h6>Ori de câte ori cineva vizitează acest site, serverele sunt capabile să colecteze anumite date ce ne permit să urmărim numărul de vizite pe site. Aceste informații sunt folosite cu scopul de a continua procesul de îmbunătățire și dezvoltare a website-ului. Astfel, datele trimise către serverele noastre nu includ date personale care ar permite urmarirea si localizarea vizitatorilor individuali. Prin date personale înțelegem informațiile care ar putea trimite în mod specific la o persoană sau entitate –  nume sau adresă de email.</h6>
        </p>
        <p>

            <h4>Informatiile tertilor</h4>
            <h6>Site-ul nostru conține link-uri către alte site-uri care nu sunt proprietatea Takida Terminal, nu sunt incluse în acesta sau legate în mod clar de acesta (cu excepția site-urilor proprii de companie, care sunt distinct marcate ca fiind parte din site-urile Takida Terminal). Prin urmare, nu putem oferi nicio garanție cu privire la conținutul sau practicile de confidențialitate ale acestor site-uri. În vederea acestei clarificări, prin "terț" se înțelege orice persoană, entitate sau grup de persoane și/sau entități care nu este și nici nu a fost declarat în calitate de membru, angajat, afiliat sau subsidiară a Takida Terminal.</h6>
        </p>
        <p>

            <h4>Acuratetea informatiilor</h4>
            <h6>Takida Terminal are entități diferite, dar în site-ul nostru folosim termenii de ”Noi”, în momentul în care ne referim la companiile din cadrul Takida Terminal, în general, sau în cazul în care nu este nevoie de a identifica în mod specific un anumit membru al grupului. Takida Terminal face toate eforturile posibile pentru a oferi în acest site informații cât mai exacte, dar nu poate garanta acuratețea, exhaustivitatea și actualitatea tuturor informațiilor furnizate de la sine înțeles au de către terțe părți. Prin urmare, orice vizitator ce folosește informațiile conținute în acest site, face acest lucru pe propriul risc.</h6>
        </p>
    </>
}
function TermsAndConditions() {
    const {  i18n } = useTranslation();

    const getContent = () => {
        const currentLanguage = i18n.language;
        switch(currentLanguage) {
            case 'en':
                return enContent();
            case 'ro':
                return roContent();
            default:
                return enContent();
        }
    };
    return <Container fluid className={'page page-term-condition'}>
        <Row className={'section section-home-gray'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col>{getContent()}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default TermsAndConditions;
