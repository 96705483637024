import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import '../assets/scss/Services.scss';

const enContent = () => {
    return <>
        <div className={'text-center'}><h2>Privacy Policy</h2></div>
        <br/>
        <br/>
        <div>
            <h3>1.Purpose of the Privacy Policy</h3>
            <p>TAKIDA TERMINAL S.R.L. treats with utmost importance the relationship with customers, distributors,
                suppliers, employees, job seekers as well as with visitors and other interested parties. The company
                aims to provide an adequate level of protection for the processing of personal data in order to maintain
                and improve its business relations, trust and reputation.</p>
            <p>Privacy policy complies with the principles of the General Regulation of the European Union on the
                protection of data subjects with regard to the processing of personal data and on their free movement
                679/2016 (hereinafter referred to as "RGPD"). The regulation aims to protect the fundamental rights and
                freedoms of individuals in the EU in the context of the globalisation of the economy and the
                digitalisation of communications, in particular their right to the protection of personal data. The GDPR
                puts the transparency and legality of the processing as reference principles to provide individuals with
                the information and control they need when processing their personal data.</p>
            <p>The privacy policy aims to provide an internationally applied framework within TAKIDA to achieve an
                adequate level of protection of personal data for the benefit of all parties involved.</p>
        </div>
        <div>
            <h3>2. Scope of this policy</h3>
            <p>This policy applies to the processing of personal data by TAKIDA. Personal data is any information
                relating to an identified or identifiable natural person, such as biographical information (name, birth
                data, etc.), employment data (addresses, position, position, etc.), telephone numbers and e-mail
                addresses, etc.) online identifier (IP address) or to one or more elements specific to its physical,
                physiological, genetic, mental, economic, economic identity, cultural or social.</p>
            <p>This policy includes generally accepted data protection principles, without replacing the existing legal
                framework. The policy is in accordance with European law and national law on the processing of personal
                data and applies to TAKIDA card transactions . The Company undertakes with this policy to fully comply
                with applicable national and European laws regarding the protection of personal data and their free
                movement.</p>
            <p>This policy does not apply to identification data of legal entities, such as companies or other
                organizations with legal personality.</p>
            <p>This policy does not apply to anonymous data, such as statistical data. However, the mere absence of a
                name does not imply that the data are anonymous, it should be impossible to directly or indirectly
                identify a data subject.</p>
            <p>This policy may be modified under the coordination of the company administrator and the Data Protection
                Officer. You can find the latest version of this policy on our website.</p>
        </div>
        <div>
            <h3>3.Definitions</h3>
            <h4>Operator</h4>
            <p>The natural or legal person, public authority, agency or other body which, alone or jointly with others,
                determines the purposes and means of processing personal data; where the purposes and means of such
                processing are determined by Union or national law, the controller or the specific criteria for its
                designation may be laid down by applicable law.</p>
            <h4>Data on health</h4>
            <p>Personal data relating to the physical or mental health of a natural person, including the provision of
                health services, which disclose information about his or her health.</p>
            <h4>The data subject</h4>
            <p>An identified or identifiable natural person whose personal data are processed. An identifiable natural
                person is a person who can be identified, directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, or, location data, an online identifier or one or
                more elements specific to its physical, physiological, genetic, psychic, economic, cultural or social
                identity.</p>
            <h4>Personal data</h4>
            <p>Any information concerning an identified or identifiable natural person ("the data subject").</p>
            <h4>Violation of the security of personal data</h4>
            <p>A security breach that results in the destruction, loss, alteration, unauthorized disclosure or
                unauthorized access to personal data transmitted, stored or otherwise processed.</p>
            <h4>Machining</h4>
            <p>Any operation or set of operations performed on personal data or on sets of personal data, with or
                without the use of automated means, such as collection, recording, organisation, organisation,
                structuring, storing, adapting or modifying, retrieving, consulting, using, disclosing the transmission,
                dissemination or otherwise making available, alignment or combination, restriction, erasure or
                destruction.</p>
            <h4>The person empowered by the controller</h4>
            <p>The natural or legal person, public authority, agency or other body that processes personal data on
                behalf of the controller.</p>
            <h4>Pseudonymisation</h4>
            <p>The processing of personal data in such a way that personal data cannot be attributed to a specific data
                subject without the use of additional information, provided that such additional information is kept
                separately and is subject to technical and organisational measures to ensure that such data is not
                attributed to an identified or identifiable natural person.</p>
            <h4>Consignee</h4>
            <p>A natural or legal person, public authority, agency or other body to which personal data are disclosed,
                whether or not a third party. However, public authorities to which personal data may be communicated in
                the context of a specific investigation in accordance with Union or national law shall not be regarded
                as recipients; the processing of these data by the respective public authorities will comply with the
                applicable data protection rules in accordance with the purposes of the processing.</p>
            <h4>Restriction of processing</h4>
            <p>Marking of personal data stored for the purpose of limiting future processing.</p>
        </div>
        <div>
            <h3>4.Identification of the Operator</h3>
            <p>THE TAKIDA TERMINAL S.R.L.</p>
            <p>Headquarters: Bucharest, sector 1, ONE FLOREASCA CITY, no. 159-165, 9th floor.</p>
            <p>NAIL: RO46184165</p>
            <p>Order number in the Trade Register: J40/9808/24.05.2022</p>
            <p>Tel.: <a href={'tel:+40215552820'}>021 555 2820</a></p>
            <p>Website: www.takidaterminal.com</p>
        </div>
        <div>
            <h3>5.Principles relating to the processing of personal data</h3>
            <h4>5.1 Legality and equity</h4>
            <p>Personal data must be processed lawfully and fairly in relation to the data subject. Any processing of
                personal data will be lawful only to the extent that the processing is based on a basis of processing
                provided by law. When special categories of personal data are processed, this will be done only when one
                of the conditions of derogation specified by law applies.</p>
            <h4>5.2 Transparency</h4>
            <p>The controller shall take appropriate measures to provide the data subject with any information relating
                to the processing of personal data in a concise, transparent, understandable and easily accessible form
                using clear and simple language. The information shall be provided in writing or by other means,
                including where appropriate in electronic form.</p>
            <h4>5.3 Purpose limitation</h4>
            <p>The purposes for which the personal data are processed must be explicit and legitimate and determined at
                the time of their collection. Personal data may not be processed in a manner incompatible with these
                purposes.</p>
            <h4>5.4 Data minimisation</h4>
            <p>Personal data must be adequate, relevant and limited to what is necessary in relation to the purposes for
                which they are processed.</p>
            <h4>5.5 Data accuracy</h4>
            <p>Personal data must be accurate and, if necessary, kept up to date. Reasonable measures must be taken to
                ensure that personal data which are inaccurate, having regard to the purposes for which they are
                processed, are erased or rectified without delay.</p>
            <h4>5.6 Limitation of data storage</h4>
            <p>Personal data may not be processed for a longer period than is strictly necessary for that processing.
                Once the personal data are no longer necessary for the purpose of the processing, they must be
                anonymized or deleted.</p>
            <p>Personal data may be stored for longer periods to the extent that they will be processed exclusively for
                archiving purposes in the public interest, for scientific or historical research purposes or for
                statistical purposes.</p>


            <h4>5.7 Data integrity and confidentiality</h4>
            <p>The processing of personal data must be carried out in a way that ensures their security, including
                protection against unauthorized or illegal processing, loss, destruction or accidental damage.
                Confidentiality commitments are established with employees, consultants and other parties who have
                access to personal data. In addition, a password-based access restriction system ensures that
                individuals can only access the personal data they need to perform their duties.</p>
            <h4>5.8 Responsibility of data</h4>
            <p>TAKIDA must be able to demonstrate compliance with the principles of lawfulness, fairness, transparency,
                minimization, purpose limitation, storage limitation, integrity and confidentiality of the processing of
                personal data. Data protection policies, control elements, procedures, checklists and other measures
                that constitute the data protection framework shall be documented systematically.</p>
            <h4>5.9 Data protection from the moment of conception and by default</h4>
            <p>Control elements and how to comply with the principles related to the processing of personal data are
                being proactively developed from the design and development phase of processing operations. The
                strictest privacy settings (in relation to the amount of data collected, the extent of processing, the
                storage period and its accessibility) must be applied by default to any processing. Compliance with the
                principle of data protection at the time of design and by default is a functional requirement throughout
                the life of the company's operations involving the processing of personal data.</p>

        </div>
        <div>
            <h3>6. Informing the Data Subjects regarding the Processing of Personal Data</h3>
            <p>TAKIDA processes personal data for the following purposes:</p>
            <h4>6.1 Management of signing and conducting contracts with customers</h4>
            <p>Purpose-signing and conducting contracts with customers and to send information to such customers about
                similar products and services.</p>
            <p>The lawfulness of processing-executing a contract to which the data subjects are a party or to take steps
                at their request prior to the conclusion of a contract.</p>
            <p>Categories of personal data: Identification data of the persons from the client necessary for the
                performance of the operational contract: name and surname of the shareholders, percentage,
                identification number (passport/ID), and, the name and surname, the function of the directors or persons
                authorized to sign, the data of the contact persons within the company (name and surname, telephone,
                email), data of the administrator (name, surname, first name, last name of the father, last name of the
                father, personal numeric code, type of identity document, date and place of issuance of the identity
                document, address, nationality), data of the real beneficiary (name, surname, surname of the father,
                type of identity document, type of, date and place of issue, address, nationality, country of residence,
                CNP, public function), signature; Identification data of the customer's transport service subcontractors
                required to perform the contract (loading instruction): driver's name and surname, driver's phone,
                driving license, contact person from the recipient (name, surname, location);Identification data of the
                subcontractors of transport services necessary for the performance of the contract (customs broker,
                terminal ): name, surname, signature, email address, telephone, address, position.</p>
            <p>Storage period - for the period of the contractual relationship and after the conclusion of the
                contractual relationship for a period of 10 years according to Annex no. 1 Article (A) no. 38 of Order
                2635/2015 on financial accounting documents.</p>
            <h4>6.2 Management of the signing and performance of contracts with suppliers</h4>
            <p>The purpose of signing and conducting contracts with suppliers</p>
            <p>Legality of processing-executing contracts with suppliers and to take steps before concluding a
                contract.</p>
            <p>Categories of personal data: Identification data of the persons from the operator necessary for the
                performance of the operational contract: name, surname, signature, email address, telephone, function,
                address; Identification data of the persons from the sender and/or from the recipient: name, surname,
                signature, email address, phone, fax, function, address, surname, driver name, driving license, driving
                license, contact data; Identification data of persons from service subcontractors of the operator
                necessary for the performance of the contract (customs commissioner, terminal, etc.): name, surname,
                signature, email address, email address, phone, function, address.</p>
            <p>Storage period - for the period of the contractual relationship and after the conclusion of the
                contractual relationship for a period of 10 years according to Annex no. 1 Article (A) no. 38 of Order
                2635/2015 on financial accounting documents.</p>
            <h4>6.3 Customer/supplier relationship management (contact form)</h4>
            <p>Purpose of answering questions/messages received from customers/suppliers in order to sign a contract
                between parties.</p>
            <p>Legality of processing - steps to conclude contracts with customers/suppliers.</p>
            <p>Categories of personal data - name, surname, e-mail, telephone, locality, message.</p>
            <p>Storage period - if a contract has been concluded between the parties we will store these messages during
                the contractual relationship and after the conclusion of the contractual relationship for a period of 10
                years according to Annex no. 1 art. A item 38 of the Order 2635/2015 on financial accounting documents,
                and if a contract between the parties was not concluded for a maximum period of 90 days.</p>
            <h4>6.4 Management of employees and candidates</h4>
            <h5>6.4.1 Employee recruitment and selection</h5>
            <p>Purpose: recruitment and selection of employees</p><br/>

            <p>Legality of processing: steps to sign the individual labor contract.</p>
            <p>Categories of personal data: Identification data: full name, date of birth, gender, domicile/residence,
                citizenship; Contact details: telephone number and e-mail address; Data on studies followed and
                qualifications obtained; Data on previous professional experience; Additional data provided voluntarily
                by you in your CV or for the conclusion of the individual employment contract (e.g., photography,
                photography, hobbies, data on the possession of a driving license, residence permit, residence
                registration certificate, marital status, etc.).</p>
            <p>Storage period - 1 year after collection.</p>
            <h5>6.4.2 Signing and unfolding management CIM</h5>
            <p>The purpose of signing and carrying out an individual employment contract including payroll processing,
                revisal, personal evaluation, labor protection, issuance of proficiency sheet, issuance of documents at
                the request of the data subject.</p>
            <p>Legality of processing - Signing and performing individual labor contract according to the updated Labor
                Code 2018 (law no.53/2003), (related purposes: payroll, Med. Labor, ITM, OSH, CIM termination,
                archiving, authorities, secured access, business cards, annual evaluation, issuance of documents on
                request, legal holidays, medical leave, changing position framing, archiving, travel for work ).</p>
            <p>The categories of personal data - name, surname, domicile, serial number and identity document number,
                validity, CNP, education and training, salary and benefits, family information, administrative
                information e.g. Bank account, performance at work, location data, health data required by national law,
                telephone number, email address, criminal record, work book, internal statements, internal statements,
                civil status papers.</p>
            <p>Storage period - 75 years from the termination of the individual employment contract according to Annex 6
                of the Law on National Archives L.16/1996.</p>
            <h4>6.5. Management of the activity of promoting products and services</h4>
            <p>Purpose-Promoting products and services on the company website and social networks.</p>
            <p>Legality of processing - obtaining consent.</p>
            <p>Categories of personal data - photo/video image.</p>
            <p>Storage period - until withdrawal of consent by the data subject.</p>
            <h4>6.6. Processing of video information on the perimeter and access area in offices</h4>
            <p>Purpose-processing of video information in public access areas in company offices</p>
            <p>The lawfulness of processing and processing is necessary based on the legitimate interest pursued by the
                operator to ensure the protection and protection of persons, goods and values as protection against
                crime.</p>
            <p>Categories of personal data - video image of the data subject, biometric data</p>
            <p>Storage period - 30 days</p>
            <h4>6.7. Managing complaints and disputes</h4>
            <p>Purpose-prevention and detection of illegal activities and dispute management.</p>
            <p>The legality of the processing and processing is necessary for the purposes of the legitimate interest
                pursued by the operator - the protection and promotion of the economic, commercial, social and financial
                interests of TAKIDA</p>
            <p>Categories of personal data - name, surname, function, telephone, email address, other data relevant to
                specific situations.</p>
            <p>Storage period - during the litigation period and subsequently for a period of 5 years from the end of
                the year when the trial ended.</p>
        </div>
        <div>
            <h3>7.Addressees</h3>
            <p>State authorities (ANAF, ITM, etc.);</p>
            <p>Company management</p>
            <p>Suppliers directly/indirectly involved in the marketing, marketing process (eg IT services, courier
                services, etc.);</p>
            <p>Healthcare providers</p>
            <p>Labour protection and PSI service providers</p>
            <p>Providers of banking, financial and insurance services</p>
            <p>Security and protection service providers</p>
            <p>IT service providers</p>
            <p>Payroll related service providers</p>
        </div>
        <div>
            <h3>8.Rights of Data Subjects</h3>
            <p>Your rights regarding your personal data:</p>
            <p>Under Regulation (EU) 679/2016, you may exercise any of the following rights:</p>
            <ul>
                <li>the right of access to personal data concerning you;</li>
                <li>to request the rectification or updating of personal data when they are inaccurate or incomplete;
                </li>
                <li>the right to request the deletion of personal data if, for example, the data is no longer necessary
                    for processing, or the data subject objects to the processing of data or the processing of personal
                    data has been unlawfully carried out ;
                </li>
                <li>the right to data portability, in the sense of transferring your personal data to another controller
                    of personal data in a structured format that can be read automatically;
                </li>
                <li>the right to restrict the processing of personal data in cases where, for example, the lawfulness of
                    the processing or the accuracy of the data is contested. Once restricted, the controller may only
                    store the personal data, may not use it further, and may lift the restriction only after informing
                    the data subject of that intention. Each recipient to whom the personal data have been communicated
                    must be informed of any rectification, erasure or restriction that has been made to comply with this
                    request; and;
                </li>
                <li>the right to object to processing if it is carried out in order to protect our legitimate interest
                    if your particular situation gives you reasonable grounds;
                </li>
                <li>the right not to be subject to a decision based solely on automated processing, including profiling,
                    which produces legal effects that concern you or affect you to a significant extent;
                </li>
                <li>the right to lodge a complaint with us and/or the competent data protection authority;</li>
                <li>the right to withdraw your consent at any time for the processing of personal data to which you have
                    previously consented;
                </li>
            </ul>
            <p>For the exercise of these rights, as well as for any additional questions regarding this notice or in
                connection with TAKIDA's use of personal data, please contact us by choosing any of the communication
                methods described below, specifying your name, postal address or email address (depending on how you
                want us to communicate), your telephone number and the purpose of your request.</p>
        </div>
        <div>
            <h3>9. Security and confidentiality</h3>
            <p>TAKIDA and its employees have implemented technical and organisational measures to protect personal data
                against accidental or unauthorised destruction, accidental loss, alteration or unauthorised access.
                These measures shall be developed taking into account the results of the risk analysis of the processing
                carried out and shall be assessed and tested at regular intervals. Security and privacy require
                awareness-raising measures, training and communication of personal data protection issues within the
                organization (training for all employees who have access to personal data, allocation of
                responsibilities, etc.). Techniques such as data minimization, limitation of the period of information
                storage, pseudonymization, encryption, confidentiality agreements, must be taken into account, physical
                integrity of data carriers and logging according to access rights depending on the role played in the
                processing of personal data. In principle, TAKIDA does not transfer personal data outside the European
                Economic Area, however if this is required, if this is so, we assure you that we will sign the standard
                clauses for the transfer of personal data for countries located outside the European Economic Area
                issued by the European Commission.</p>
        </div>
        <div>
            <h3>10.The person empowered by the controller</h3>
            <p>TAKIDA has required processors, that is, any subcontractor who processes personal data on behalf of
                TAKIDA to implement appropriate technical and organisational security measures. When taking these
                measures into account, due consideration should be given to the current state of information systems,
                nature, object, object, the context and purposes of the processing and the risks related to the
                violation of the rights and freedoms of natural persons.has concluded a processing agreement with each
                of the persons empowered in accordance with the requirements of the GDPR.</p>
        </div>
        <div>
            <h3>11. Transfer of data</h3>
            <p>The transfer of personal data is guaranteed in terms of security in the countries of the European Union /
                The European Economic Area and other countries considered by the European Commission to ensure an
                adequate level of their protection. When personal data is exported by TAKIDA acting as an operator in
                the European Union to recipients in countries that do not provide an adequate level of protection, the,
                TAKIDA has ensured that appropriate safeguards, standard contractual clauses on the protection of
                personal data or approved certification mechanisms are implemented.</p>
        </div>
        <div>
            <h3>12. The person responsible for the protection of personal data</h3>
            <p>The company management ensures that the data protection officer receives the appropriate resources,
                training and independence guarantees necessary to effectively manage their tasks.</p>
            <p>The person responsible for data protection has the following tasks:</p>
            <p>Information and advice to employees and processors of TAKIDA 679/deleted who deal with the processing of
                personal data relating to their obligations under Regulation 679/2016 and other provisions of European
                Union and national law on data protection.</p>
            <p>Monitoring compliance with Regulation 679/2016, other provisions of European Union or national law
                relating to the protection of personal data and the policy of TAKIDA or of the person empowered by the
                company with regard to data protection personal including the allocation of responsibilities and actions
                to raise awareness and train the personnel involved in processing operations and may carry out regular
                checks, reviews and audits of documents, procedures and operations.</p>
            <p>Provision of advice on request in relation to the data protection impact assessment and monitoring of its
                officials in accordance with Article 35 of Regulation 679/2016.</p>
            <p>Cooperation with the supervisory authority (ANSPDCP).</p>
            <ul>
                <li>Assuming the role of contact point for ANSPDCP on issues related to processing including prior
                    consultation referred to in Article 36 of the GDPR and if necessary consultation on any other
                    matter.
                </li>
                <li>Coordinates the handling of the requests of the data subjects.</li>
            </ul>
            <p>Contact details</p>
            <p>The person responsible for the protection of personal data :</p>
            <ul>
                <li>email: <a href={'mailTo:ana.ion@takidapte.com'}>ana.ion@takidapte.com</a></li>
                <li>correspondence address: Bucharest, sector 1, ONE FLOREASCA CITY, no. 159-165, 9th floor.</li>
            </ul>
        </div>
    </>
}
const roContent = () => {
    return <>
        <div className={'text-center'}><h2>Politica de confidentialitate</h2></div>
        <br/>
        <br/>
        <div>
            <h3>1. Scopul Politicii de Confidentialitate</h3>
            <p>TAKIDA TERMINAL S.R.L. trateaza cu maximă importanță relația cu clienții, distribuitorii, furnizorii,
                angajații, solicitanții de locuri de muncă precum si cu vizitatorii și alte părți interesate. Compania
                își propune să ofere un nivel adecvat de protecție a prelucrarii datelor cu caracter personal pentru
                a-și mentine si îmbunătăți relațiile de afaceri, încrederea și reputația.</p>
            <p>Politica de confidentialitate respecta principiile Regulamentului General al Uniunii Europene privind
                protectia persoanelor vizate cu privire la prelucrarea datelor cu caracter personal si privind libera
                circulatie a acestora 679/2016 (denumit în continuare "RGPD"). Regulamentul își propune să protejeze
                drepturile si libertatile fundamentale ale persoanelor fizice din UE în contextul globalizarii economiei
                și digitalizarii comunicatiilor, in special dreptul acestora la protecția datelor cu caracter personal.
                RGPD pune transparența si legalitatea prelucrarilor ca principii de referinta pentru a oferi indivizilor
                informaţiile și controlul de care au nevoie atunci cand le sunt prelucrate datele cu caracter
                personal.</p>
            <p>Politica de confidențialitate urmărește să ofere un cadru aplicat la nivel internațional în cadrul TAKIDA
                pentru a atinge un nivel adecvat de protecție a datelor cu caracter personal, în beneficiul tuturor
                părților implicate.</p>
        </div>
        <div>
            <h3>2. Domeniul de aplicare al acestei politici</h3>
            <p>Această politică se aplică prelucrării datelor cu caracter personal de către TAKIDA. Datele personale
                reprezintă orice informație referitoare la o persoană fizică identificată sau identificabilă, cum ar fi
                informații biografice (nume, date de naștere, etc.), date privind locul de muncă (adrese, poziție,
                numere de telefon și adrese de e-mail, etc.) identificator online (adresa IP) sau la unul sau mai multe
                elemente specifice proprii identității sale fizice, fiziologice, genetice, psihice, economice, culturale
                sau sociale.</p>
            <p>Această politică cuprinde principii general acceptate privind protecția datelor, fără a înlocui cadrul
                legal existent. Politica este in acord cu dreptul Europen și dreptul intern cu privire la prelucrarea
                datelor cu caracter personal și se aplică operațiunilor din cardul TAKIDA . Compania se angajează cu
                această politică să respecte pe deplin legile naționale și Europene aplicabile cu privire la protecția
                datelor personale și libera circulație a acestora.</p>
            <p>Această politică nu se aplică datelor de identificare a persoanelor juridice, cum ar fi companiile sau
                alte organizații cu personalitate juridică.</p>
            <p>Această politică nu se aplică datelor anonime, cum ar fi datele statistice. Cu toate acestea, simpla
                absență a unui nume nu implică faptul că datele sunt anonime, ar trebui să fie imposibilă identificarea
                directă sau indirectă a unei persoane vizate.</p>
            <p>Această politică poate fi modificată sub coordonarea administratorului companiei si a Ofiterului de
                protectie a datelor. Puteți găsi cea mai recentă versiune a acestei politici pe site-ul nostru.</p>
        </div>
        <div>
            <h3>3. Definitii</h3>
            <h4>Operator</h4>
            <p>Persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu
                altele, stabileste scopurile și mijloacele de prelucrare a datelor cu caracter personal; în cazul în
                care scopurile și mijloacele unei astfel de prelucrări sunt stabilite prin dreptul Uniunii sau dreptul
                intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevăzute de legea
                aplicabilă.</p>
            <h4>Date privind sanatatea</h4>
            <p>Datele personale referitoare la sănătatea fizică sau psihică a unei persoane fizice, inclusiv la
                furnizarea serviciilor de sănătate, care dezvăluie informații despre starea sa de sănătate.</p>
            <h4>Persoana vizată</h4>
            <p>O persoană fizică identificată sau identificabilă al carui/ carei date cu caracter personal sunt
                procesate. O persoană fizică identificabilă este o persoana care poate fi identificată, direct sau
                indirect, în special prin referire la un element de identificare cum ar fi un nume, un număr de
                identificare, date de localizare, un identificator online sau la unul sau mai multe elemente specifice
                proprii identitatii sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.</p>
            <h4>Date cu caracter personal</h4>
            <p>Orice informații privind o persoană fizică identificată sau identificabilă ("persoana vizată").</p>
            <h4>Incalcarea securitatii datelor cu caracter personal</h4>
            <p>O încălcare a securității care duce la distrugerea, pierderea, modificarea, dezvăluirea neautorizată sau
                accesul neautorizat la datele cu caracter personal transmise, stocate sau prelucrate în alt mod.</p>
            <h4>Prelucrare</h4>
            <p>Orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor
                de date cu caracter personal, cu sau fara utilizarea de mijloace automatizate, cum ar fi colectarea,
                înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, recuperarea, consultarea,
                utilizarea, dezvăluirea transmiterea, diseminarea sau punerea la dispoziție în alt mod, alinierea sau
                combinarea, restricționarea, ștergerea sau distrugerea.</p>
            <h4>Persoana împuternicită de operator</h4>
            <p>Persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează datele cu
                caracter personal în numele operatorului.</p>
            <h4>Pseudonimizare</h4>
            <p>Prelucrarea datelor cu caracter personal în așa fel încât datele cu caracter personal să nu poată fi
                atribuite unei anumite persoane vizate fără utilizarea unor informații suplimentare, cu condiția ca
                aceste informații suplimentare să fie păstrate separat și să facă obiectul unor măsuri tehnice și
                organizatorice care să asigure neatribuirea respectivelor date unei persoane fizice identificate sau
                identificabile.</p>
            <h4>Destinatar</h4>
            <p>O persoană fizică sau juridică, o autoritate publică, o agenție sau un alt organism, căruia i se
                dezvăluie datele cu caracter personal, indiferent dacă este sau nu un terț. Cu toate acestea,
                autoritățile publice carora li se poate comunica date cu caracter personal în cadrul unei anchete anume,
                în conformitate cu dreptul Uniunii sau dreptul intern nu vor fi considerate destinatari; prelucrarea
                acestor date de către autoritățile publice respective va respecta normele aplicabile privind protecția
                datelor în conformitate cu scopurile prelucrării.</p>
            <h4>Restrictionarea procesării</h4>
            <p>Marcarea datelor cu caracter personal stocate în scopul limitării prelucrării în viitor.</p>
        </div>
        <div>
            <h3>4. Identificarea Operatorului</h3>
            <p>
                TAKIDA TERMINAL S.R.L.<br/>
                Sediul social: Bucuresti, sector 1, ONE FLOREASCA CITY, nr. 159-165, etaj 9.<br/>
                CUI: RO46184165<br/>
                Număr de ordine în Registrul Comerțului: J40/9808/24.05.2022<br/>
                Tel.: <a href={'tel:+40215552820'}>021 555 2820</a><br/>
                Website: www.takidaterminal.ro
            </p>
        </div>
        <div>
            <h3>5. Principii referitoare la prelucrarea datelor cu caracter personal</h3>
            <h4>5.1 Legalitate și echitate</h4>
            <p>Datele cu caracter personal trebuie prelucrate în mod legal și echitabil în raport cu persoana vizată.
                Orice prelucrare a datelor cu caracter personal va fi legală numai în măsura în care prelucrarea se
                bazează pe un temei de prelucrare prevazut de lege. Atunci când sunt procesate categorii speciale de
                date cu caracter personal, aceata se va face numai atunci cand se aplica una din conditiile de derogare
                specificate de lege.
            </p>
            <h4>5.2 Transparenta</h4>
            <p>Operatorul ia masuri adecvate pentru a furniza persoanei vizate orice informații referitoare la
                prelucrarea datelor cu caracter personal într-o formă concisă, transparentă, inteligibilă si usor
                accesibilă, utilizând un limbaj clar si simplu. Informațiile se furnizează în scris sau prin alte
                mijloace, inclusiv atunci cand este oportun în format electronic.</p>
            <h4>5.3 Limitarea scopului</h4>
            <p>Scopurile pentru care sunt prelucrate datele cu caracter personal trebuie să fie explicite și
                legitime și să fie determinate în momentul colectării acestora. Datele cu caracter personal nu pot
                fi prelucrate într-o manieră incompatibilă cu aceste scopuri.</p>
            <h4>5.4 Minimizarea datelor</h4>
            <p>Datele personale trebuie să fie adecvate, relevante și limitate la ceea ce este necesar în raport cu
                scopurile pentru care sunt prelucrate.</p>
            <h4>5.5 Acuratetea datelor</h4>
            <p>Datele cu caracter personal trebuie să fie corecte și, dacă este necesar, să fie actualizate. Trebuie
                să se ia măsuri rezonabile pentru a se asigura că datele cu caracter personal care sunt inexacte,
                având în vedere scopurile pentru care sunt prelucrate, sunt șterse sau rectificate fără
                întârziere.</p>
            <h4>5.6 Limitarea stocarii datelor</h4>
            <p>Datele cu caracter personal nu pot fi prelucrate o perioada mai mare decât este strict necesar pentru
                prelucrarea respectivă. Din momentul în care datele personale nu mai sunt necesare pentru realizarea
                scopului prelucrării, acestea trebuie anonimizate sau șterse.,
            </p>
            <p>Datele cu caracter personal pot fi stocate pe perioade mai lungi in masura in care acestea vor fi
                prelucrate exclusiv în scopuri de arhivare in interes public, in scopuri de cercetare
                științifică sau istorică ori în scopuri statistice.
            </p>
            <h4>5.7 Integritatea și confidentialitatea datelor</h4>
            <p>Prelucrarea datele personale trebuie să fie făcută într-un mod care asigură securitatea
                acestora inclusiv protecția împotriva prelucrării neautorizate sau ilegale, împotriva
                pierderii, a distrugerii sau a deteriorării accidentale. Sunt instituite angajamente de
                confidențialitate cu angajații, consultanții și alte părți care au acces la datele cu
                caracter personal. În plus, un sistem de restricționare a accesului bazat pe parolă asigură
                că persoanele pot accesa numai la acele date cu caracter personal de care au nevoie pentru
                exercitarea atribuțiilor care le revin.</p>
            <h4>5.8 Responsabilitatea datelor</h4>
            <p>TAKIDA trebuie să poată demonstra conformitatea cu principiile legalității, echitații,
                transparenței, minimizării, limitării scopului, limitării stocării, integrității și
                confidențialității prelucrarii datelor cu caracter personal. Politicile de protecție a
                datelor, elementele de control, procedurile, listele de verificare și alte măsuri care
                constituie cadrul de protecție a datelor sunt documentate sistematic.</p>
            <h4>5.9 Protectia datelor începand cu momentul conceperii și în mod implicit</h4>
            <p>Elementele de control și modul de respectare a principiilor legate de prelucarea datelor cu
                caracter personal sunt dezvoltate în mod proactiv din faza de proiectare și elaborare a
                operațiunilor de procesare. Cele mai stricte setări de confidențialitate (în raport cu
                cantitatea de date colectate, amploarea procesării, perioada de stocare și accesibilitatea
                acesteia) trebuie aplicate în mod implicit la orice procesare. Respectarea principiului
                protecției datelor din momentul proiectarii și în mod implicit este o cerință funcțională pe
                întreaga durată de viață a operațiunilor companiei care implică prelucrarea datelor cu
                caracter personal.</p>

        </div>
        <div>
            <h3>6. Informarea Persoanelor Vizate cu privire la Prelucrarea Datelor cu Caracter Personal</h3>
            <p>TAKIDA procesează date cu caracter personal în următoarele scopuri:</p>
            <h4>6.1 Managementul semnării și derulării contractelor cu clientii</h4>
            <p>Scopul-semnarea și derularea contractelor cu clienții și pentru a trimite informații unor astfel de
                clienți despre produse și servicii similare.</p>
            <p>Legalitatea prelucrării-executarea unui contract la care persoanele vizate sunt parte sau pentru a face
                demersuri la cererea acestora înainte de încheierea unui contract.</p>
            <p>Categoriile de date cu caracter personal: Date de identificare ale persoanelor de la client necesare in
                derularea contractului operational: numele si prenumele actionarilor, procentul, numar de identificare
                (pasaport/ID), numele si prenumele, functia directorilor sau a persoanelor autorizate sa semneze, datele
                persoanelor de contact din cadrul companiei (nume si prenume, telefon, email), date ale
                administratorului (nume, prenume, prenumele tatalui, codul numeric personal, tipul documentului de
                identitate, data si locul emiterii documentului de identitate, adresa, nationalitatea), datele
                beneficiarului real (nume, prenume, prenumele tatalui, tipul documentului de identitate, data si locul
                emiterii, adresa, nationalitatea, tara de resedinta, CNP, functia publica), semnatura;Datele de
                identificare ale subcontractorilor de servicii de transport ai clientului necesare pentru derularea
                contractului (loading instruction): numele si prenumele soferului, telefonul soferului, permisul de
                conducere, persoana de contact de la destinatar (nume, prenume, locatie);Datele de identificare ale
                subcontractorilor de servicii de transport necesare pentru derularea contractului (comisionar vamal,
                terminal ): nume, prenume, semnatura, adresa de email, telefon, adresa, functie.</p>
            <p>Perioada de stocare- pe perioada relației contractuale și după încheierea relației contractuale pe o
                perioada de 10 ani conform Anexei nr. 1 articolul (A) punctul 38 din Ordinului 2635/2015 privind
                documentele financiar contabile.</p>
            <h4>6.2 Managementul semnării și derulării contractelor cu furnizorii</h4>
            <p>Scopul- semnarea și derularea contractelor cu furnizorii</p>
            <p>Legalitatea prelucrarii-executarea contractelor cu furnizorii si pentru a face demersuri inainte de
                incheierea unui contract.</p>
            <p>Categoriile de date cu caracter personal: Date de identificare ale persoanelor de la operator necesare in
                derularea contractului operational: nume, prenume, semnatura, adresa de email, telefon, functie, adresa;
                Date de identificare ale persoanelor de la expeditor si/sau de la destinatar: nume, prenume, semnatura,
                adresa de email, telefon, fax, functie, adresa, nume, prenume sofer, permis de conducere, date de
                contact; Date de identificare ale persoanelor de la subcontractori de servicii ai operatorului necesare
                pentru derularea contractului ( comisionar vamal, terminal etc. ): nume, prenume, semnatura, adresa de
                email, telefon, functie, adresa.</p>
            <p>Perioada de stocare- pe perioada relației contractuale și după încheierea relației contractuale pe o
                perioada de 10 ani conform Anexei nr. 1 articolul (A) punctul 38 din Ordinului 2635/2015 privind
                documentele financiar contabile.</p>
            <h4>6.3 Managementul relatiilor cu clientii/furnizorii (formular de contact)</h4>
            <p>Scopul- raspunderea la intrebarile/mesajele primite de la clienti/furnizori in vederea semnarii unui
                contract intre parti.</p>
            <p>Legalitatea prelucrarii- demersuri in vederea incheierii contractelor cu clientii/furnizorii.</p>
            <p>Categoriile de date cu caracter personal- nume, prenume, e-mail, telefon, localitate,mesaj.</p>
            <p>Perioada de stocare- daca s-a incheiat un contract intre parti vom stoca aceaste mesaje pe perioada
                relatiei contractuale si dupa incheierea relatiei contractuale pe o perioada de 10 ani conform Anexei
                nr. 1 art. A pct. 38 din Ordinul 2635/2015 privind documentele financiar contabile, iar in cazul in care
                nu s-a incheiat un contract intre parti pe o perioada maxima de 90 de zile.</p>
            <h4>6.4 Managementul angajatilor si candidatilor</h4>
            <h5>6.4.1 Recrutare și selectie angajati</h5>
            <p>Scopul: recrutare și selecție angajați</p>

            <p>Legalitatea prelucrarii: demersuri in vederea semnarii contractului individual de munca.</p>
            <p>Categoriile de date cu caracter personal: Date de identificare: numele complet, data nașterii, sexul,
                domiciliul/ reședința, cetățenia; Date de contact: numărul de telefon și adresa de e-mail; Date privind
                studiile urmate şi calificările obținute; Date privind experiența profesională anterioară; Date
                suplimentare furnizate în mod voluntar de dumneavoastră în CV sau în vederea încheierii contractului
                individual de muncă (e.g. fotografie, hobby-uri, date privind deținerea unui permis de conducere, permis
                de ședere, certificat de înregistrare a rezidenței, starea civila etc.).</p>
            <p>Perioada de stocare- 1 an de la colectare.</p>
            <h5>6.4.2 Management semnare si derulare CIM</h5>
            <p>Scopul- semnare și derulare contract individual de muncă inclusiv prelucrarile pentru salarizare,
                revisal, evaluare personal, protecția muncii, eliberare fișă de aptitudini, eliberare documente la
                cererea persoanei vizate.</p>
            <p>Legalitatea prelucrarii- Semnare și derulare contract individual de munca conform Codului Muncii
                actualizat 2018 (legea nr.53/2003), ( scopuri conexe: salarizare, Med. Muncii, ITM, SSM, încetare CIM,
                arhivare, autorități, acces securizat, carți de vizită, evaluare anuală, eliberare documente la cerere,
                concedii legale, concedii medicale, modificare pozitie incadrare, calatorii in interes de serviciu
                ).</p>
            <p>Categoriile de date cu caracter personal-nume, prenume, domiciliu, serie și număr act de identitate,
                valabilitate, CNP, educație și instruire, salariu și beneficii, informații familie, informații
                administrative ex. Cont bancar, performanța la locul de muncă, date de locație, date de sănătate cerute
                de dreptul intern, numar de telefon, adresă email, cazier, carte de muncă, declarații interne, acte
                stare civila.</p>
            <p>Perioada de stocare- 75 de ani de la încetarea contractului individual de muncă conform Anexei 6 din
                Legea Arhivelor Nationale L.16/1996.</p>
            <h4>6.5. Managementul activitătii de promovare produse si servicii</h4>
            <p>Scopul- Promovare produse si servicii pe site-ul companiei si retelele de socializare.</p>
            <p>Legalitatea prelucrarii- obtinerea consimtamantului.</p>
            <p>Categoriile de date cu caracter personal- imagine foto/video.</p>
            <p>Perioada de stocare- pana la retragerea consimtamantului de catre persoana vizata.</p>
            <h4>6.6. Prelucrare informatie video pe perimetrul si zona de acces in birouri</h4>
            <p>Scopul-prelucrarea informației video în zonele publice de acces in birourile companiei</p>
            <p>Legalitatea prelucrarii-prelucrarea este necesară în baza interesului legitim urmarit de operator- pentru
                a asigura paza si protectia persoanelor, bunurilor si valorilor ca protectie impotriva
                criminalitatii.</p>
            <p>Categoriile de date cu caracter personal-imagine video persoana vizata, date biometrice</p>
            <p>Perioada de stocare- 30 de zile</p>
            <h4>6.7. Gestionarea reclamatiilor și litigiilor</h4>
            <p>Scopul-prevenirea și detectarea activităților ilegale și gestionarea litigiilor.</p>
            <p>Legalitatea prelucrarii-prelucrarea este necesară în scopul interesului legitim urmărit de operator-
                protejarea și promovarea intereselor economice, comerciale, sociale și financiare ale TAKIDA</p>
            <p>Categoriile de date cu caracter personal-nume, prenume, funcție, telefon, adresă de mail, alte date
                relevante pentru situațiile specifice.</p>
            <p>Perioada de stocare- pe perioada desfășurarii litigiului și ulterior pe o perioada de 5 ani de la
                incheierea anului la care s-a terminat procesul.</p>
        </div>
        <div>
            <h3>7. Destinatari</h3>
            <p>Autoritățile statului (ANAF, ITM etc.);</p>
            <p>Managementul companiei</p>
            <p>Furnizori implicați în mod direct/indirect în procesul de marketing, comercializare (ex. servicii de IT,
                servicii de curierat, etc);</p>
            <p>Furnizori de servicii medicale</p>
            <p>Furnizori de servicii de protectia muncii și PSI</p>
            <p>Furnizori de servicii bancare, servicii financiare și de asigurare</p>
            <p>Furnizori de servicii de paza și protectie</p>
            <p>Furnizori de servicii IT</p>
            <p>Furnizori de servicii asociate salarizarii</p>
        </div>
        <div>
            <h3>8. Drepturile Persoanelor Vizate</h3>
            <p><strong>Drepturile</strong> pe care le aveți în ceea ce privește datele dumneavoastră cu caracter
                personal:</p>
            <p>Conform Regulamentului (UE) 679/2016, vă puteți exercita oricare dintre urmatoarele drepturi:</p>
            <ul>
                <li>dreptul de acces la datele cu caracter personal care vă privesc;</li>
                <li>dreptul de a solicita rectificarea sau actualizarea datelor cu caracter personal atunci cand acestea
                    sunt inexacte sau incomplete;
                </li>
                <li>dreptul de a solicita stergerea datelor cu caracter personal dacă, de exemplu, datele nu mai sunt
                    necesare pentru procesare, sau persoana vizată obiectează în ceea ce privește prelucrarea datelor
                    sau prelucrarea datelor cu caracter personal a fost facută în mod ilegal ;
                </li>
                <li>dreptul la portabilitatea datelor, în sensul transferului datelor dumneavoastră personale catre un
                    alt operator de date personale într-un format structurat ce poate fi citit automat;
                </li>
                <li>dreptul la restricționarea prelucrării datelor cu caracter personal în cazurile în care, de exemplu,
                    legalitatea procesării sau exactitatea datelor este contestată. Odată restricționată, operatorul
                    poate doar să stocheze datele personale, nu poate să le folosească mai departe și poate să ridice
                    restricția numai după ce a informată persoana vizată despre această intenție. Fiecarui destinatar
                    căruia i-au fost comunicate datele cu caracter personal trebuie să fie informat cu privire la orice
                    rectificare, ștergere sau restricție care a fost efectuată pentru a se conforma cu acesta
                    solicitare;
                </li>
                <li>dreptul de a va opune prelucrarii în cazul în care aceasta se efectuează în vederea protejării
                    interesului nostru legitim dacă situația dumneavoastră particulară vă oferă motive întemeiate;
                </li>
                <li>dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrare automată, inclusiv crearea
                    de profiluri, care produce efecte juridice care vă privesc sau vă afectează într-o masură
                    semnificativă;
                </li>
                <li>dreptul de a depune o plangere la noi și/sau la autoritatea competentă privind protecția datelor;
                </li>
                <li>dreptul de a vă retrage consimțământul, în orice moment, pentru prelucrarea datelor personale, la
                    care ați consimțit anterior;
                </li>
            </ul>
            <p>Pentru exercitarea acestor drepturi, precum și pentru orice întrebari suplimentare cu privire la această
                notificare sau în legatură cu utilizarea de catre TAKIDA a datelor personale, vă rugăm să ne contactați
                alegând oricare din modalitățile de comunicare descrise mai jos, precizând, totodată, numele
                dumneavoastră, adresa postala sau de email (în funcție de modalitatea prin care doriți să comunicam),
                numărul dumneavoastră de telefon precum și scopul cererii dumneavoastră.</p>
        </div>
        <div>
            <h3>9. Securitatea și confidentialitatea</h3>
            <p>TAKIDA și angajații acesteia au implementat măsurile tehnice și organizatorice pentru a proteja datele cu
                caracter personal împotriva distrugerii accidentale sau neautorizate, a pierderii accidentale, a
                modificărilor sau accesului neautorizat. Aceste măsuri sunt dezvoltate ținând seama de rezultatele
                analizei de risc a prelucrarilor efectuate și sunt evaluate și testate la intervale regulate.
                Securitatea și confidențialitatea necesită măsuri de sporire a gradului de conștientizare, instruire si
                comunicarea a problemelor legate de protecția datelor cu caracter personal în cadrul organizației
                (training pentru toti angajatii care au acces la date cu caracter personal, alocarea
                responsabilităților, etc.). Trebuie luate în considerare tehnici cum ar fi minimizarea datelor,
                limitarea perioadei de stocăre a informației, pseudonimizarea, criptarea, contracte de
                confidențialitate, integritate fizică a suporturilor de date și logarea conform drepturilor de acces în
                funcție de rolul avut în prelucrarea datelor cu caracter personal. In principiu, TAKIDA nu transfera
                date cu caracter personal in afara Spatiului Economic European, totusi in cazul in care acest lucru se
                impune, va asiguram ca vom semna minim Clauzele standard pentru transferal datelor cu caracter personal
                pentru tarile localizate in afara Spatiului Economic European emise de Comisia Europeana.</p>
        </div>
        <div>
            <h3>10.Persoana împuternicită de operator</h3>
            <p>TAKIDA a impus persoanelor împuternicite de operator, adică orice subcontractant care prelucrează date cu
                caracter personal în numele TAKIDA , să implementeze măsuri de securitate tehnice și organizaționale
                adecvate. Atunci când se iau în considerare aceste măsuri, trebuie avute în vedere în mod corespunzător
                stadiul actual al sistemelor informatice, natura, obiectul, contextul și scopurile prelucrării precum și
                riscurile privind incalcarea drepturilor și libertăților persoanelor fizice. a încheiat un acord de
                procesare cu fiecare dintre persoanele împuternicite în conformitate cu cerintele RGPD.</p>
        </div>
        <div>
            <h3>11. Transferul datelor</h3>
            <p>Transferul datelor cu caracter personal este garantat din punct de vedere al securitatii în țările din
                Uniunea Europeană / Spațiul Economic European și din alte țări considerate de Comisia Europeană ca
                asigurând un nivel adecvat de protecție a acestora. Atunci când datele cu caracter personal sunt
                exportate de TAKIDA care acționează ca operator în Uniunea Europeană catre destinatari din țările care
                nu oferă un nivel adecvat de protecție, TAKIDA s-a asigurat că sunt puse în aplicare măsuri de protecție
                adecvate, clauze contractuale standard privind protecția datelor cu caracter personal sau mecanisme de
                certificare aprobate.</p>
        </div>
        <div>
            <h3>12. Persoana responsabilă de protectia datelor cu caracter personal</h3>
            <p>Managementul companiei se asigură că persoana responsabilă de protecția datelor primește resursele
                adecvate, instruirea și garanțiile de independență necesare pentru a-și gestiona eficient sarcinile.</p>
            <p>Persoana responsabilă cu protecția datelor are urmatoarele sarcini:</p>
            <ul>
                <li>Informarea și consilierea angajaților și persoanelor împuternicite de TAKIDA , care se ocupă de
                    prelucrarea datelor cu caracter personal cu privire la obligațiile care le revin în temeiul
                    regulamentului 679/2016 și altor dispoziții de drept al Uniunii Europene și al dreptului intern
                    referitoare la protecția datelor.
                </li>
                <li>Monitorizarea respectării Regulamentului 679/2016, a altor dispozitii de drept al Uniunii Europene
                    sau de drept intern referitoare la protecția datelor cu caracter personal și a politicii TAKIDA sau
                    a persoanei împuternicite de companie în ceea ce privește protecția datelor cu caracter personal
                    inclusiv alocarea responsabilităților și acțiunile de sensibilizare și de instruire a personalului
                    implicat în operațiunile de prelucrare și poate efectua verificări periodice, revizuiri și audituri
                    ale documentelor, procedurilor și operațiunilor.
                </li>
                <li>Furnizarea de consiliere la cerere în ceea ce privește evaluarea impactului asupra protecției
                    datelor și monitorizarea funcționarii acesteia în conformitate cu prevederile articolului 35 din
                    regulamentul 679/2016.
                </li>
                <li>Cooperarea cu autoritatea de supraveghere (ANSPDCP).</li>
                <li>Asumarea rolului de punct de contact pentru ANSPDCP privind aspectele legate de prelucrare inclusiv
                    consultarea prealabilă menționată la articolul 36 din RGPD precum și dacă este cazul consultarea cu
                    privire la orice altă chestiune.
                </li>
                <li>Coordonează tratarea cererilor persoanelor vizate.</li>
            </ul>
            <p>Date de contact</p>
            <p>Persoana responsabilă de protecția a datelor cu caracter personal :</p>
            <ul>
                <li>e-mail: <a href="mailto:ana.ion@takidapte.com">ana.ion@takidapte.com</a></li>
                <li>adresa corespondență: Bucuresti, sector 1, ONE FLOREASCA CITY, nr. 159-165, etaj 9.</li>
            </ul>
        </div>
    </>
}

function PrivacyPolicy() {
    const {i18n} = useTranslation();

    const getContent = () => {
        const currentLanguage = i18n.language;
        switch (currentLanguage) {
            case 'en':
                return enContent();
            case 'ro':
                return roContent();
            default:
                return enContent();
        }
    };
    return <Container fluid className={'page page-policy'}>
        <Row className={'section section-home-gray'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col>{getContent()}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default PrivacyPolicy;
