import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import services_p1 from "../assets/images/services-p1.png";
import services_s1 from "../assets/images/services_s1.png";
import services_s2 from "../assets/images/services_s2.png";
import {Trans, useTranslation} from "react-i18next";
import '../assets/scss/Services.scss';
import {Link} from "react-router-dom";

function Services() {
    const { t } = useTranslation();
    return <Container fluid className={'page-services'}>
        <Row>
            <Col className={'full_image-box g-0'}>
                <Image src={services_p1} width={'100%'} height={'auto'}/>
                <div className="gradient-overlay"></div>
                <div className={'full_image-text-box'}>
                    <Container>
                        <Row className={'align-items-end justify-content-between'}>
                            <Col lg={6} xs={12} className={'image-title'}>{t('SERVICES')}</Col>
                            <Col lg={5} xs={12} className={'image-subtitle'}>
                                {t('services_subtitle')}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={5}>
                            <Container className={'text-standards'}>
                                <Row>
                                    <Col className={''}>
                                        <div className={'label-box'}>
                                            <div className={'label-name'}>{t(`Services`)}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-title'}>{t(`PETROLEUM PRODUCTS`)}</Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-text'} >
                                        <Trans i18nKey={`services_text_s1`} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/product-petroleum" type={'button'}><Button className={'btn btn-home-section'} >{t('Read More')}</Button></Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} lg={7} className={'home-section-image-box mt-3 mt-lg-0'}>
                            <Image src={services_s1} className={'w-100'} />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-gray'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={7} className={'home-section-image-box'}>
                            <Image src={services_s2} className={'w-100'} />
                        </Col>
                        <Col xs={12} lg={5} className={'mt-3 mt-lg-0'}>
                            <Container className={'text-standards'}>
                                <Row>
                                    <Col className={''}>
                                        <div className={'label-box'}>
                                            <div className={'label-name'}>{t(`Services`)}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-title'}>{t(`PETROCHEMICAL PRODUCTS`)}</Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-text'} >
                                        <Trans i18nKey={`services_text_s2`} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/product-petrochemical" type={'button'}><Button className={'btn btn-home-section'}>{t('Read More')}</Button></Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default Services;
