import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Image} from "react-bootstrap";
import flag_en from "../assets/images/flag_en.png";
import flag_ro from "../assets/images/flag_ro.png";

function Languages() {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language).then(()=> console.log(`Change language to ${language}`));
    };
    const isActiveLanguage = (language) => {
        return i18n.language.startsWith(language);
    };

    return (
        <div className={'cta-languages'}>
            <Button variant={'secondary'} className={isActiveLanguage('en') ? 'active' : ''} onClick={() => changeLanguage('en')}>
                <Image src={flag_en}  className={'w-100'} />
            </Button>
            <Button variant={'secondary'} className={isActiveLanguage('ro') ? 'active' : ''} onClick={() => changeLanguage('ro')}>
                <Image src={flag_ro}  className={'w-100'} />
            </Button>
        </div>
    );
}

export default Languages;
