import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import product_petrochemical_p1 from "../assets/images/products_petrochemical-p1.png";
import {useTranslation} from "react-i18next";
import '../assets/scss/Services.scss';

const enContent = () => {
    return <>
        <p>Liquefied petroleum gas (LPG) is one of the most commonly used types of
        petroleum products, essential in numerous industrial and residential
        applications. Obtained through the refining process of crude oil, LPG
            primarily consists of propane and butane, or a combination thereof.</p>
        <p>A distinctive feature of LPG is its ability to liquefy under high pressure,
        facilitating its transportation and storage safely and efficiently. This
        versatility makes it an ideal choice for various applications, including
        heating homes, cooking, and in the automotive industry, preferred for
            vehicles operating on gas.</p>
        <p>Through a liquefaction process, LPG can be converted into liquid for safe
        transportation through pipelines or specialized containers to destinations
        worldwide. This adaptability and versatility position it prominently in the
        energy industry, contributing to efficiently and responsibly meet the fuel
            and energy needs.</p>
    </>
}
const roContent = () => {
    return <>
        <p>Gazul petrolier lichefiat (GPL) reprezintă unul dintre cele mai frecvent utilizate
        tipuri de produse petroliere, fiind esențial în numeroase aplicații industriale și
        rezidențiale. Obținut prin procesul de rafinare a țițeiului, GPL-ul este compus în
            principal din propan și butan, sau o combinație a acestora.</p>
        <p>O caracteristică distinctivă a GPL-ului este capacitatea sa de a trece în stare
        lichidă sub presiune înaltă, ceea ce facilitează transportul și stocarea acestuia în
        condiții sigure și eficiente. Această versatilitate îl face o opțiune ideală pentru
        utilizarea în diverse aplicații, inclusiv încălzirea locuințelor, gătitul și în industria
            auto, fiind preferat pentru vehiculele care funcționează cu gaz.</p>
        <p>Prin intermediul unui proces de lichefiere, GPL-ul poate fi convertit în lichid
        pentru a fi transportat în siguranță prin conducte sau containere specializate
        către destinații din întreaga lume. Această capacitate de adaptare și versatilitate
        îl plasează într-o poziție cheie în industria energetică, contribuind la satisfacerea
        nevoilor de combustibil și energie în mod eficient și responsabil.</p>
    </>
}

function ProductPetroleum() {
    const {t, i18n} = useTranslation();

    const getContent = () => {
        const currentLanguage = i18n.language;
        switch (currentLanguage) {
            case 'en':
                return enContent();
            case 'ro':
                return roContent();
            default:
                return enContent();
        }
    };
    return <Container fluid className={'page-services product-page'}>
        <Row>
            <Col className={'full_image-box g-0'}>
                <Image src={product_petrochemical_p1} width={'100%'} height={'auto'}/>
                <div className="gradient-overlay"></div>
                <div className={'full_image-text-box'}>
                    <Container>
                        <Row className={'align-items-end justify-content-between'}>
                            <Col lg={6} className={'image-title'}>{t('PETROLEUM PRODUCTS')}</Col>
                            <Col lg={5} className={'image-subtitle'}>
                                {t('product_petroleum_subtitle')}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col>{getContent()}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default ProductPetroleum;
