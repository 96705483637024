import React, {useState} from "react";
import { Link, useLocation  } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import {Col, Container, Nav, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import HeaderNavbarCtas from "./HeaderNavbarCtas";
import logo from "../assets/images/logo.png";
import Image from "react-bootstrap/Image";
import Languages from "./Languages";
import LanguagesMobile from "./LanguagesMobile";

export default function NavBar() {
    const { t } = useTranslation();
    const location = useLocation();
    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        console.log('action handleIsOpen',  isOpen);
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        console.log('action closeSideBar');
        setOpen(false)
    }

    return (
        <div className="NavBar p-0">
            <Container className={'h-100 p-0'}>
                <Row className={'h-100'}>
                    <Col className={'h-100'}>
                        <div className="menuVisible justify-content-between h-100">
                            <Nav className={'align-items-center align-content-center'} defaultActiveKey="/home" as="ul">
                                <Nav.Item as="li">
                                    <Link to="/home" className={`NavItems ${location.pathname === '/home' ? 'active' : ''}`}><span>{t('Home')}</span></Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Link to="/about-us"  className={`NavItems ${location.pathname === '/about-us' ? 'active' : ''}`}><span>{t('About us')}</span></Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Link to="/services"  className={`NavItems ${location.pathname === '/services' ? 'active' : ''}`}><span>{t('Services')}</span></Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Link to="/contact"  className={`NavItems ${location.pathname === '/contact' ? 'active' : ''}`}><span>{t('Contact')}</span></Link>
                                </Nav.Item>
                            </Nav>
                            <HeaderNavbarCtas/>
                        </div>
                        <div className="burgerVisible justify-content-between h-100">
                            <Container fluid>
                                <Row className={"h-100"}>
                                    <Col xs={6}  className={"h-100"}>
                                        <Link to="/home" className={'d-flex w-100 h-100'}><Image src={logo} style={{ "height":"100%" }} /></Link>
                                    </Col>
                                    <Col xs={4} className={'text-end h-100'}>
                                        <LanguagesMobile />
                                    </Col>
                                    <Col xs={2} className={"h-100"}>
                                        <Menu
                                            right
                                            isOpen={isOpen}
                                            onOpen={handleIsOpen}
                                            onClose={handleIsOpen}
                                        >
                                            <div className={'bg-ww wrapper-burger-menu'}>
                                                <Languages/>
                                                <Link to="/" onClick={closeSideBar} className="menu-items">
                                                    {t('Home')}
                                                </Link>
                                                <Link to="/about-us" onClick={closeSideBar} className="menu-items">
                                                    {t('About us')}
                                                </Link>
                                                <Link to="/services" onClick={closeSideBar} className="menu-items">
                                                    {t('Services')}
                                                </Link>
                                                <Link to="/contact" onClick={closeSideBar} className="menu-items">
                                                    {t('Contact')}
                                                </Link>
                                            </div>
                                        </Menu>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
