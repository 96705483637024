import React, { useState } from 'react';
import {Form, Button, Row, Col, Container, Alert} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga";
import {Link} from "react-router-dom";
const backendServer = "https://takidaterminal.ro";
const initialFormState = {
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    message: ''
}
const initialFeedbackMessageState = { message: '', type: '' }
const checkBoxLabelRo = () => {
    return <>Accept <Link to="/policy" target="_blank" rel="noopener noreferrer">Politica de Confidențialitate</Link></>
}
const checkBoxLabelEn = () => {
    return <>Accept <Link to="/policy" target="_blank" rel="noopener noreferrer">the Privacy Policy</Link></>
}
const ContactForm = () => {
    const { t, i18n } = useTranslation();

    const getContent = () => {
        const currentLanguage = i18n.language;
        switch(currentLanguage) {
            case 'ro': return checkBoxLabelRo();
            case 'en': return checkBoxLabelEn();
            default: return checkBoxLabelEn();
        }
    }
    const [form, setForm] = useState(initialFormState);
    const [feedbackMessage, setFeedbackMessage] = useState(initialFeedbackMessageState);
    const [allowTerms, setAllowTerms] = useState(false);

    const changeAllowTerms = (e) => {
        setAllowTerms(e.target.checked);
    };

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!allowTerms){
            setFeedbackMessage({ message: t('Nu ai acceptat termenii si conditiile'), type: 'danger' });
            return;
        }

        ReactGA.event({
            category: 'User',
            action: 'Form contact'
        });

        setFeedbackMessage(initialFeedbackMessageState);
        fetch(`${backendServer}/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data.success){
                    setForm(initialFormState);
                    setFeedbackMessage({ message: t('Mesajul a fost trimis cu succes!'), type: 'success' });
                }
                if(!data.success){
                    setFeedbackMessage({ message: t('Eroare la trimiterea mesajului. Încearcă din nou.'), type: 'danger' });
                }
            })
            .catch(error => {
                console.error('Eroare la trimiterea email-ului:', error)
                setFeedbackMessage({ message: t('Eroare la trimiterea mesajului. Încearcă din nou.'), type: 'danger' });

            });
    };

    return (
        <Container className={'contact-form'}>
            <Form onSubmit={handleSubmit}>
                <Row className={'mb-4'}>
                    <Col md={6} className={'mb-4 mb-lg-0'}>
                        <Form.Group controlId="formName">
                            <Form.Control
                                type="text"
                                name="name"
                                required
                                value={form.name}
                                onChange={handleChange}
                                placeholder={t("Name*")}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formEmail">
                            <Form.Control
                                type="email"
                                name="email"
                                required
                                value={form.email}
                                onChange={handleChange}
                                placeholder={t("Email*")}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className={'mb-4'}>
                    <Col md={6} className={'mb-4 mb-lg-0'}>
                        <Form.Group controlId="formPhoneNumber">
                            <Form.Control
                                type="text"
                                name="phoneNumber"
                                value={form.phoneNumber}
                                onChange={handleChange}
                                placeholder={t("Phone Number*")}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formCity">
                            <Form.Control
                                type="text"
                                name="city"
                                value={form.city}
                                onChange={handleChange}
                                placeholder={t("City*")}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className={'mb-4'}>
                    <Col xs={12} >
                        <Form.Group controlId="formMessage" className={'mb-4'}>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="message"
                                value={form.message}
                                onChange={handleChange}
                                placeholder={t("Your Message")}
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Row className={'mb-4'}>
                    {feedbackMessage.message && (
                        <Col xs={12} >
                            <Alert variant={feedbackMessage.type === 'success' ? 'success' : 'danger'}>
                                {feedbackMessage.message}
                            </Alert>
                        </Col>
                    )}
                    <Col xs={12} lg={8} className={'align-self-center'}>
                        <Form.Group controlId="formPolicy" className={'d-flex gap-3'}>
                            <Form.Check
                                type="switch"
                                name={'allow_policy'}
                                checked={allowTerms}
                                onChange={e => changeAllowTerms(e)}
                            />
                            <Form.Label>{getContent()}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={4} className={'text-end'}>
                        <Button variant="primary" type="submit" className={'btn-submit'}>
                            {t('Submit Message')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default ContactForm;
