import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import LinkContactEmail from "./LinkContactEmail";
import LinkContactPhone from "./LinkContactPhone";
import ContactForm from "./ContactForm";

function ContactComponent() {
    const { t } = useTranslation();
    return (
        <Container className={'contact-box'}>
            <Row>
                <Col>
                    <div className={'label-box'}>
                        <div className={'label-name'}>{t('Contact')}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={4}>
                    <div className={'h1 font-aire-bold-pro'}>{t('Get in touch with us')}</div>
                    <div className={'h6 mobile-subtitle font-futura-pt'}>{t('At Takida Terminal, we’re not just in the business of LPG distribution, but in the business of innovation, excellence, and making a positive impact. Located on the stunning Black Sea coast, our Mangalia terminal has been lightning up businesses and industries across the region with commitment to delivering the highest quality LPG.')}</div>
                    <Col className={'contact-links d-flex flex-column justify-content-start'}>
                        <div className={'mb-4'}><LinkContactEmail /></div>
                        <div><LinkContactPhone /></div>
                    </Col>
                </Col>

                <Col xl={8}>
                    <ContactForm />
                </Col>
            </Row>
        </Container>
    );
}

export default ContactComponent;