import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import MenuNavbar from './Navbar';
import Image from 'react-bootstrap/Image';

import logo from '../assets/images/logo.png';
import LinkContactPhone from "./LinkContactPhone";
import LinkContactEmail from "./LinkContactEmail";
import {Link} from "react-router-dom";

function Header() {
    return (
        <header>
            <Container fluid>
                <Row className={'row-logo d-none d-lg-block'}>
                    <Col>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <Link to="/home" className={'d-flex w-100'}><Image src={logo} height={150} /></Link>
                                </Col>
                                <Col className={'contact-links d-flex justify-content-end'}>
                                    <div className={'me-4'}><LinkContactEmail /></div>
                                    <div><LinkContactPhone /></div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className={"menu"}>
                    <MenuNavbar />
                </Row>
            </Container>
        </header>
    );
}

export default Header;
