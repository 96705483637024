import React from 'react';
import {Col, Container, Nav, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ContactComponent from "./ContactComponent";
import Image from "react-bootstrap/Image";
import footerImage from '../assets/images/footer-img.png'
import logo from "../assets/images/logo.png";
import LinkContactEmail from "./LinkContactEmail";
import LinkContactPhone from "./LinkContactPhone";
import {Link} from "react-router-dom";

function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <Container fluid className={'contact-box-fluid'}>
                <Row>
                    <Col>
                        <ContactComponent />
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'m-0 p-0'}>
                <Row className={'m-0 p-0'}>
                    <Col className={'m-0 p-0'}>
                        <Image src={footerImage} className={'w-100'}/>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className={'row-gray-footer'}>
                    <Col>
                        <Container className={'tabs-footer'}>
                            <Row>
                                <Col xs={8} md={3} lg={3} className={'footer-logo-box'}>
                                    <Link to="/home" ><Image src={logo} className={'w-100'}/></Link>
                                </Col>
                                <Col xs={4} md={3} lg={3} className={'d-none d-lg-flex align-self-center'}>
                                    <div>{t('Pages')}</div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className={'row-black-footer'}>
                    <Col>
                        <Container className={'footer-links-box'}>
                            <Row>
                                <Col xs={12} md={3} lg={3}>
                                    <div>
                                        {t('Distribution Of Petroleum And Derivatives - wholesale of petrochemical products')}
                                    </div>
                                    <div className={'contact-links d-flex flex-column justify-content-start'}>
                                        <div className={'mb-4'}><LinkContactEmail /></div>
                                        <div><LinkContactPhone /></div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} className={'mb-3 mb-lg-0'}>
                                    <Nav className={' flex-row flex-lg-column justify-content-start'} variant="underline" defaultActiveKey="/home" as="ul">
                                        <Nav.Item as="li">
                                            <Link to="/home" className="NavItems">{t('Home')}</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/about-us" className="NavItems">{t('About us')}</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/services" className="NavItems">{t('Services')}</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/contact" className="NavItems">{t('Contact')}</Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className={'section section-home-gray section-terms-policy'}>
                    <Col>
                        <Container>
                            <Row className={'justify-content-center gap-2'}>
                                <Col className={'align-items-end justify-content-end d-flex'}><Link to={'/terms'} className={'text-white text-decoration-none'}>{t('Terms and Conditions')}</Link></Col>
                                <Col><Link to={'/policy'} className={'text-white text-decoration-none'}>{t('Privacy Policy')}</Link></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
