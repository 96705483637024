import React from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import homeVideo from '../assets/videos/callatis_Gas-4K_2.mp4'
import imgHome1 from '../assets/images/home_p1.png'
import imgHome2 from '../assets/images/home_p2.png'
import imgHome3 from '../assets/images/home_p3.png'
import imgHome4 from '../assets/images/home_p4.png'
import iconOurVision from '../assets/images/icon-our-vision.png';
import iconHourglass from '../assets/images/icon-hourglass.png';

import logo_vitol from '../assets/images/logo_vitol.png';
import logo_vitol_hover from '../assets/images/logo_vitol_hover.png';
import logo_penthol from '../assets/images/logo_penthol.png';
import logo_penthol_hover from '../assets/images/logo_penthol_hover.png';
import logo_petkim from '../assets/images/logo_petkim.png';
import logo_petkim_hover from '../assets/images/logo_petkim_hover.png';

import {Button, Col, Container, Row, Image} from "react-bootstrap";
import HomeTextSection from "./HomeTextSection";
import ImageHoverFadeInComponent from "./ImageHoverFadeInComponent";

function Home() {
    const { t } = useTranslation();

    return (<Container fluid className={'d-block page-home'}>
        <Row>
            <Col className={'video-box'}>
                <video className={'w-100 video'} autoPlay loop muted={true} playsInline disablePictureInPicture>
                    <source src={homeVideo} type="video/mp4"/>
                    {t('Your browser does not support the video tag.')}
                </video>
                <div className="gradient-overlay"></div>
                <div className={'video-text-box'}>
                    <Container>
                        <Row className={'align-items-end'}>
                            <Col className={'video-title'}>Takida Terminal</Col>
                            <Col className={'video-subtitle'}>
                                <b>{t('Excellence in delivering energy solutions')}</b>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
        <Row className={'section section-home-black-gray'}>
            <Col className={'dots-world'}>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={7} className={'home-section-image-box'}>
                            <Image src={imgHome1}  className={'w-100'} />
                        </Col>
                        <Col xs={12} lg={5}>
                            <HomeTextSection section={'s1'} />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-gray-black'}>
            <Col className={'dots-world'}>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={5} className={'order-2 order-lg-1'}>
                            <HomeTextSection section={'s2'} />
                        </Col>
                        <Col xs={12} lg={7} className={'home-section-image-box order-lg-2'}>
                            <Image src={imgHome2} className={'w-100'} />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-black-gray'}>
            <Col className={'dots-world'}>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={7} className={'home-section-image-box'}>
                            <Image src={imgHome3} className={'w-100'} />
                        </Col>
                        <Col xs={12} lg={5}>
                            <HomeTextSection section={'s3'} />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-gray'}>
            <Col>
                <Container>
                    <Row>
                        <Col xs={12} lg={6} className={'home-card card-white'}>
                            <p className={'card-title'}>{t('PETROLEUM PRODUCTS')}</p>
                            <p className={'font-futura-pt'}>{t('LIQUEFIED PETROLEUM GAS (LPG) is one of the most common types of petroleum products. Liquefied Petroleum Gas is obtained by refining crude oil.')}</p>
                            <Link to="/product-petroleum" type={'button'}><Button>{t('Read More')}</Button></Link>
                        </Col>
                        <Col xs={12} lg={6} className={'home-card card-black'}>
                            <p className={'card-title'}>{t('PETROCHEMICAL PRODUCTS')}</p>
                            <p className={'font-futura-pt'}>{t('One of the most versatile and widely available materials is polypropylene.')}</p>
                            <Link to="/product-petrochemical" type={'button'}><Button>{t('Read More')}</Button></Link>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container className={'section-our-partners'}>
                    <Row>
                        <Col className={'title'}>{t('Our Partners')}</Col>
                    </Row>
                    <Row>
                        <Col className={'text font-futura-pt'}>{t('We are honoured to work with worldwide renowned partners. Discover some of them here.')}</Col>
                    </Row>
                    <Row>
                        <Col className={'imgs-box'}>
                            <Container fluid>
                                <Row>
                                    <Col xs={3} className={'col-img-box bl p-0'}>
                                        <ImageHoverFadeInComponent src={logo_petkim} hoverSrc={logo_petkim_hover}/>
                                    </Col>
                                    <Col xs={3} className={'col-img-box p-0'}>
                                        <ImageHoverFadeInComponent src={logo_penthol} hoverSrc={logo_penthol_hover}/>
                                    </Col>
                                    <Col xs={3} className={'col-img-box p-0'}>
                                        <ImageHoverFadeInComponent src={logo_vitol} hoverSrc={logo_vitol_hover}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={7} className={'home-section-image-box'}>
                            <Image src={imgHome4} className={'w-100'} />
                        </Col>
                        <Col xs={12} lg={5}>
                            <Container className={'text-standards'}>
                                <Row>
                                    <Col className={''}>
                                        <div className={'label-box'}>
                                            <div className={'label-name'}>{t(`STANDARDS`)}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-title'}>{t(`STANDARDS`)}</Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-text'} >
                                        <Trans i18nKey={`LPG distribution maintains strict safety protocols, ensuring the secure transportation and storage of liquefied petroleum gas. Compliance with regulatory standards guarantees operational integrity and minimises environmental risks.`} />
                                    </Col>
                                </Row>
                                <Row className={'subsection-our-vision'}>
                                    <Col xs={12} md={6} lg={6}>
                                        <Image src={iconOurVision} className={'img'}/>
                                        <p className={'title'}>
                                            <Trans i18nKey={`Our Vission`} />
                                        </p>
                                        <p className={'font-futura-pt'}>
                                            {t('We aim to be at the forefront of delivering safe, reliable, and environmentally conscious energy solutions.')}
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <Image src={iconHourglass} className={'img'}/>
                                        <p className={'title'}>
                                            {t('Shipping and storing')}
                                        </p>
                                        <p className={'font-futura-pt'}>
                                            {t('LPG transport and storage operations prioritise safety, efficiency, and sustainability guaranteeing customer satisfaction.')}
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>);
}

export default Home;
