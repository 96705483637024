import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import product_petroleum_p1 from "../assets/images/product_petroleum-p1.png";
import {useTranslation} from "react-i18next";
import '../assets/scss/Services.scss';

const enContent = () => {
    return <>
        <p>Polypropylene is indeed one of the most versatile materials, widely used
        in a variety of industries and applications. Chemically, polypropylene is a
            linear hydrocarbon polymer, with the general formula CnH2n.</p>
        <p>This material is obtained through the polymerization of propylene in the
        presence of complex metallic catalysts, which determine its structure and
        final properties. The production process of polypropylene is similar to that
        of high-density polyethylene, but differs in terms of temperature and
            pressure conditions, as well as the composition of the catalysts used.</p>
        <p>By using specific catalysts and different production conditions, a wide
        range of types of polypropylene can be obtained, each with specific
        characteristics and applications. This flexibility in the manufacturing
        process allows polypropylene to be adapted to meet the diverse
        requirements of various industries, from packaging and household
            products to automotive components and medical applications.</p>
    </>
}
const roContent = () => {
    return <>
        <p>Polipropilena este într-adevăr unul dintre cele mai versatile materiale, fiind larg
        răspândită într-o varietate de industrii și aplicații. Din punct de vedere chimic,
        polipropilena este un polimer hidrocarburic liniar, având formula generală
            CnH2n.</p>
        <p>Acest material este obținut prin polimerizarea propilenei în prezența unor
        catalizatori metalici complexi, care determină structura și proprietățile sale
        finale. Procesul de producție a polipropilenei este similar cu cel al polietilenei de
        înaltă densitate, dar diferă în ceea ce privește condițiile de temperatură și
            presiune, precum și compoziția catalizatorilor folosiți.</p>
        <p>Prin utilizarea unor catalizatori specifici și a diferitelor condiții de producție, se
        poate obține o gamă variată de tipuri de polipropilene, fiecare cu caracteristici și
        aplicații specifice. Această flexibilitate în procesul de fabricație permite
        adaptarea polipropilenei pentru a satisface cerințele diverse ale diferitelor
        industrii, de la ambalaje și produse de uz casnic la componente auto și aplicații
            medicale.</p>
    </>
}

function ProductPetrochemical() {
    const {t, i18n} = useTranslation();

    const getContent = () => {
        const currentLanguage = i18n.language;
        switch (currentLanguage) {
            case 'en':
                return enContent();
            case 'ro':
                return roContent();
            default:
                return enContent();
        }
    };
    return <Container fluid className={'page-services product-page'}>
        <Row>
            <Col className={'full_image-box g-0'}>
                <Image src={product_petroleum_p1} width={'100%'} height={'auto'}/>
                <div className="gradient-overlay"></div>
                <div className={'full_image-text-box'}>
                    <Container>
                        <Row className={'align-items-end justify-content-between'}>
                            <Col lg={6} className={'image-title'}>{t('PETROCHEMICAL PRODUCTS')}</Col>
                            <Col lg={5} className={'image-subtitle'}>
                                {t('product_petrochemical_subtitle')}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col>{getContent()}</Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default ProductPetrochemical;
