import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Image} from "react-bootstrap";
import flag_en from "../assets/images/flag_en.png";
import flag_ro from "../assets/images/flag_ro.png";

function LanguagesMobile() {
    const {i18n} = useTranslation();

    const toggleLanguages = () => {
        if (isActiveLanguage('en')) {
            i18n.changeLanguage('ro').then(() => console.log(`Change language to ro`));
            return;
        }

        i18n.changeLanguage('en').then(() => console.log(`Change language to en`));
    };
    const isActiveLanguage = (language) => {
        return i18n.language === language;
    };

    return (
        <div className={'cta-languages h-100 cta-languages-mobile'}>
            <Button variant={'secondary'} className={'active h-100 w-50 bg-transparent border-0 text-end button-flag-mobile'} onClick={() => toggleLanguages()}>
                {i18n.language === 'en' ? <Image src={flag_en} className={'w-100'}/> : <Image src={flag_ro} className={'w-100'}/>}
            </Button>
        </div>
    );
}

export default LanguagesMobile;
