import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Col, Container, Row} from "react-bootstrap";
function HomeTextSection(props) {
    const { section } = props;
    const { t } = useTranslation();

    return (
        <Container className={'section-text-box'}>
            <Row>
                <Col className={''}>
                    <div className={'label-box'}>
                        <div className={'label-name'}>{t(`home_${section}_label`)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={'home-section-title'}>{t(`home_${section}_title`)}</Col>
            </Row>
            <Row>
                <Col className={'home-section-text'} >
                    <Trans i18nKey={`home_${section}_text`} />
                </Col>
            </Row>
            <Row className={'d-none'}>
                <Col>
                    <Button className={'btn btn-home-section'} href={t(`home_${section}_link`)}>{t(`home_${section}_button`)}</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeTextSection;