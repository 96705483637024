import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactGA from 'react-ga';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import './i18n';
import './assets/scss/App.scss';
import ProductPetroleum from "./components/ProductPetroleum";
import ProductPetrochemical from "./components/ProductPetrochemical";
import TermsAndConditions from "./components/TermAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";

const TRACKING_ID = "G-9858KVL22B";
ReactGA.initialize(TRACKING_ID);

function App() {
    return (
            <Router>
                <ScrollToTop>
                    <Header />
                    <main>
                        <Routes>
                            <Route path="/contact" element={<Contact />}/>
                            <Route path="/services" element={<Services />}/>
                            <Route path="/about-us" element={<About />}/>
                            <Route path="/product-petroleum" element={<ProductPetroleum />}/>
                            <Route path="/product-petrochemical" element={<ProductPetrochemical />}/>
                            <Route path="/terms" element={<TermsAndConditions />}/>
                            <Route path="/policy" element={<PrivacyPolicy />}/>

                            <Route index element={<HomePage />}/>
                            <Route path="*" element={<HomePage />}/>
                        </Routes>
                    </main>
                    <Footer />
                </ScrollToTop>
            </Router>
    );
}

export default App;
