import React from "react";
import '../assets/scss/AboutUs.scss'
import {Col, Container, Row} from "react-bootstrap";
import aboutUs_p1 from '../assets/images/aboutus-p1.png'
import aboutUs_s1 from '../assets/images/img_aboutus-s1.png'
import Image from "react-bootstrap/Image";
import {Trans, useTranslation} from "react-i18next";
import icon_box from '../assets/images/icon-about-us-box.png';
import icon_money from '../assets/images/icon-about-us-money.png';
import icon_boat from '../assets/images/icon-about-us-boat.png';
import icon_rack from '../assets/images/icon-about-us-rack.png';
import icon_plane from '../assets/images/icon-about-us-plane.png';
import icon_chart from '../assets/images/icon-about-us-chart.png';
import icon_truck from '../assets/images/icon-about-us-truck.png';
import icon_linkdin from '../assets/images/icon-linkdin.png';
import photo_auditor_intern from '../assets/images/photo_auditor_intern.jpg';
import photo_cfo from '../assets/images/photo_cfo.jpg';
import photo_general_director from '../assets/images/photo_general_director.jpg';
import {Link} from "react-router-dom";

function About() {
    const { t } = useTranslation();
    return <Container fluid className={'page-about_us'}>
        <Row>
            <Col className={'full_image-box g-0'}>
                <Image src={aboutUs_p1} width={'100%'} height={'auto'}/>
                <div className="gradient-overlay"></div>
                <div className={'full_image-text-box'}>
                    <Container>
                        <Row className={'align-items-end'}>
                            <Col className={'image-title'}>{t('ABOUT US')}</Col>
                            <Col className={'image-subtitle'}>
                                <Trans i18nKey={'about_us_subtitle'}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Col xs={12} lg={5}>
                            <Container className={'text-standards'}>
                                <Row>
                                    <Col className={''}>
                                        <div className={'label-box'}>
                                            <div className={'label-name'}>{t(`About Us`)}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'pb-3'}>
                                        <Trans i18nKey={'about_us_under_title'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-title'}>{t(`OUR MISSION`)}</Col>
                                </Row>
                                <Row>
                                    <Col className={'home-section-text'} >
                                        <Trans i18nKey={'about_us_text_s1'} />
                                    </Col>
                                </Row>
                                <Row className={'subsection-our-vision'}>
                                    <Col xs={12} className={'d-flex align-items-center gap-3'}>
                                        <Image src={icon_box} className={'img'}/>
                                        <p className={'title m-0'}>
                                            {t('4 terminal')}
                                        </p>
                                    </Col>
                                    <Col xs={12} className={'d-flex align-items-center gap-3 d-none'}>
                                        <Image src={icon_money} className={'img'}/>
                                        <p className={'title m-0'}>
                                            {t(`5 million cube meters storage capacity globally`)}
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} lg={7} className={'home-section-image-box mt-3'}>
                            <Image src={aboutUs_s1} className={'w-100'} />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-gray'}>
            <Col>
                <Container className={'section-about_us-content'}>
                    <Row className={'justify-content-center'}>
                        <Col className={'text-center'}>
                            <div className={'label-box'}>
                                <div className={'label-name'}>{t('What we Do')}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'justify-content-center'}>
                        <Col className={'home-section-title text-center'}>{t('Values')}</Col>
                    </Row>
                    <Row className={'about_us-boxes'}>
                        <Col xs={12} md={6} lg={4} className={'about_us-box'}>
                            <Image src={icon_boat}/>
                            <p className={'title'}>{t('Corporate ethics')}</p>
                            <p className={'text font-futura-pt'}>{t('We are guided by fairness, integrity, and respect.')}</p>
                        </Col>
                        <Col xs={12} md={6} lg={4} className={'about_us-box'}>
                            <Image src={icon_rack}/>
                            <p className={'title'}>{t('Corporate responsibility')}</p>
                            <p className={'text font-futura-pt'}>{t('Responsibility for the human lives and quality standards of petrochemicals and petroleum products we deal with.')}</p>
                        </Col>
                        <Col xs={12} md={6} lg={4} className={'about_us-box'}>
                            <Image src={icon_plane}/>
                            <p className={'title'}>{t('Transparency of relationships')}</p>
                            <p className={'text font-futura-pt'}>{t('Openness and partnership in cooperation with stakeholders and society as a whole.')}</p>
                        </Col>
                        <Col xs={12} md={6} lg={4} className={'about_us-box'}>
                            <Image src={icon_chart}/>
                            <p className={'title'}>{t('Social effect')}</p>
                            <p className={'text font-futura-pt'}>{t('Whatever the Company does is done by the people and for the people')}</p>
                        </Col>
                        <Col xs={12} md={6} lg={4} className={'about_us-box'}>
                            <Image src={icon_truck}/>
                            <p className={'title'}>{t('Environment and Technologies')}</p>
                            <p className={'text font-futura-pt'}>{t('We stringently abide by the highest global environmental standards.')}</p>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        <Row className={'section section-home-black'}>
            <Col>
                <Container>
                    <Row className={'section-home-content'}>
                        <Container className={'text-standards'}>
                            <Row className={'justify-content-center'}>
                                <Col className={'text-center'}>
                                    <div className={'label-box'}>
                                        <div className={'label-name'}>{t('About us')}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'justify-content-center'}>
                                <Col className={'home-section-title text-center'}>{t('Our team')}</Col>
                            </Row>
                            <Row className={'our_team-boxes'}>
                                <Col xs={12} lg={4} className={'our_team-box'}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Image src={photo_cfo} className={'img person_photo'}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={10}>
                                                <div className={'description'}>
                                                    <p className={'person_name m-0'}>
                                                        {'Tetyana Simon'}
                                                    </p>
                                                    <p className={'person_role'}>
                                                        {t(`CFO`)}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={2}>
                                                <Link className={'link_linkedin'} to={'https://www.linkedin.com/in/tety%C3%A1na-simon-362b59a9/ '} target={'_blank'}>
                                                    <Image src={icon_linkdin} className={'linkedin_icon'}/>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col xs={12} lg={4} className={'our_team-box'}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Image src={photo_general_director} className={'img person_photo'}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={10}>
                                                <div className={'description'}>
                                                    <p className={'person_name m-0'}>
                                                        {'Razvan Tudose'}
                                                    </p>
                                                    <p className={'person_role'}>
                                                        {t(`General Director`)}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={2}>
                                                <Link className={'link_linkedin'} to={'https://www.linkedin.com/in/r%C4%83zvan-tudose-585496198/'} target={'_blank'}>
                                                    <Image src={icon_linkdin} className={'linkedin_icon'}/>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col xs={12} lg={4} className={'our_team-box'}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Image src={photo_auditor_intern} className={'img person_photo'}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={10}>
                                                <div className={'description'}>
                                                    <p className={'person_name m-0'}>
                                                        {'Ghenadie Turcanu'}
                                                    </p>
                                                    <p className={'person_role'}>
                                                        {t(`Internal Auditor`)}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={2}>
                                                <Link className={'link_linkedin'} to={'https://www.linkedin.com/in/ghenadie-turcanu-63bb8856/'} target={'_blank'}>
                                                    <Image src={icon_linkdin} className={'linkedin_icon'}/>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}

export default About;
