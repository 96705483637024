import React from 'react';
import Image from "react-bootstrap/Image";
import { useTranslation } from 'react-i18next';
import iconPhone from "../assets/images/icon-phone.png";

function LinkContactEmail() {
    const { t } = useTranslation();
    return (
        <a href="tel:0040215552820" className={'d-flex flex-row align-content-start'}>
            <div className={'box-icon-header'}><Image src={iconPhone} className={'icon-header'}/></div>
            <div className={'d-flex flex-column'}>
                <div>{t('Call Us')}</div>
                <div>021 555 2820</div>
            </div>
        </a>
    );
}

export default LinkContactEmail;