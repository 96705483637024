import React from "react";
import Image from "react-bootstrap/Image";
import iconInstagram from "../assets/images/icon-instagram.png";
import iconFacebook from "../assets/images/icon-facebook.png";
import iconTiktok from "../assets/images/icon-tictok.png";
import iconLinkdin from "../assets/images/icon-linkdin.png";
import Languages from "./Languages";

function HeaderNavbarCtas() {
    return (
        <div className={'ctas align-items-center'}>
            <div className={'social-media'}>
                <a className={'d-inline-block me-3 me-lg-4 d-none'} target={'_blank'} href={'https://www.instagram.com/takidapte'} rel="noreferrer"><Image src={iconInstagram}/></a>
                <a className={'d-inline-block me-3 me-lg-4 d-none'} target={'_blank'} href={'https://www.facebook.com/takidapte?refid=13'} rel="noreferrer"><Image src={iconFacebook}/></a>
                <a className={'d-inline-block me-3 me-lg-4 d-none'} target={'_blank'} href={'https://www.tiktok.com/@taki.da.pte.ltd'} rel="noreferrer"><Image src={iconTiktok}/></a>
                <a className={'d-inline-block me-3 me-lg-4'} target={'_blank'} href={'https://sg.linkedin.com/company/taki-da-pte-ltd'} rel="noreferrer"><Image src={iconLinkdin}/></a>
            </div>
            <Languages/>
        </div>
    );
}

export default HeaderNavbarCtas