import React from 'react';
import Image from "react-bootstrap/Image";
import iconEmail from "../assets/images/icon-email.png";
import {useTranslation} from 'react-i18next';

function LinkContactEmail() {
    const {t} = useTranslation();
    return (
        <a href="mailto:office@takidaterminal.ro"
           className={'d-flex flex-row align-content-start'}>
            <div className={'box-icon-header'}><Image src={iconEmail} className={'icon-header'}/></div>
            <div className={'d-flex flex-column'}>
                <div>{t('Email')}</div>
                <div>office@takidaterminal.ro</div>
            </div>
        </a>
    );
}

export default LinkContactEmail;